@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Hind:wght@300;400;500;600;700&display=swap');
.sidebar{
  
    height: 100%;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0%);
}
.sidebar.slide-out {
    transform: translateX(-100%);
  }
.sidebar .sidebar-title {
    display: flex;
    background-color: #303035;
    height:50px;
    color:#f3f3f3;
    letter-spacing: 1px;
    padding-left: 40px;
    cursor: pointer;
}
.sidebar .sidebar-title .title-text{
    margin-top: 12px;
    font-size:18px;
    font-family: 'Bebas Neue', cursive;
}
.sidebar .sidebar-title img{
    
    padding: 3px;
    width:26px;
    height:26px;
    margin-top: 12px;
    margin-right: 10px;
}

.sidebar .sidebar-section{
    color:#232323;
    border-bottom: thin solid #a2a2a2;
    padding-bottom: 10px;
}
.sidebar .sidebar-section .section-title{
    font-family: 'Hind', sans-serif;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    
}

.sidebar .sidebar-section .section-link{
    font-family: 'Hind', sans-serif;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
}
.sidebar .sidebar-section .section-link:hover{
    background-color: rgba(170 , 170, 220, 0.3)
}

.sidebar .sidebar-section .section-plus{
    background-image: url('../imgs/interface/toggle-menu-btn.png');
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 100px center;
    font-family: 'Hind', sans-serif;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
}
.sidebar .sidebar-section .section-plus:hover{
    background-color: rgba(170 , 170, 220, 0.3);
    background-image: url('../imgs/interface/toggle-menu-btn-over.png');
}
.sidebar .sidebar-section .section-moins{
    background-image: url('../imgs/interface/toggle-menu-moins.png');
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 110px center;
    font-family: 'Hind', sans-serif;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
}


.sidebar .sidebar-section .section-moins:hover{
    background-color: rgba(170 , 170, 220, 0.3);
    background-image: url('../imgs/interface/toggle-menu-moins-over.png');
}

.sidebar .sidebar-section .subsubIcon{
    background-image: url('../imgs/interface/sub-menu-btn.png');
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: 98% center;
}
.sidebar .sidebar-section .subsubIcon:hover{
    background-image: url('../imgs/interface/sub-menu-btn-over.png');
}

.slide-pane__content{
    padding: 0px;
    background-color: rgba(230 , 230, 230, 1);
    overflow-x: hidden;
}
.sidebarSub{
    position: absolute;
    top: 0px;
    width: 100%;
    left: 100%;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0%);
}
.sidebarSub .sidebar-title {
    display: flex;
    background-color: #303035;
    height:50px;
    color:#f3f3f3;
    letter-spacing: 1px;
    padding-left: 40px;
    cursor: pointer;
}
.sidebarSub.slide-in {
    transform: translateX(-100%);
}

.sidebarSub .sidebar-title .title-text{
    margin-top: 12px;
    font-size:18px;
    font-family: 'Bebas Neue', cursive;
}
.sidebarSub .sidebar-title img{
    padding: 3px;
    width:26px;
    height:26px;
    margin-top: 12px;
    margin-right: 10px;
}

.sidebarSub .sidebar-section{
    color:#232323;
    padding-bottom: 10px;
}
.sidebarSub .sidebar-section .section-title{
    font-family: 'Hind', sans-serif;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    
}
.sidebar .sidebar-section .section-title-center{
    display: block;
    font-family: 'Hind', sans-serif;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    
}


.sidebarSub .sidebar-section .section-link{
    font-family: 'Hind', sans-serif;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
}
.sidebarSub .sidebar-section .section-link:hover{
    background-color: rgba(170 , 170, 220, 0.3)
}



  /* Style the select */
  .filter-row select {
    padding: 0.375rem 0.75rem;
    margin: 0;
    border: none;
    border-radius: 0.25rem;
    background-color: #f8f9fa;
  }

  .filter-row{
    font-size:14px;
    margin-bottom:10px
  }
  .filter-text{
    margin-top: 7px;
  }
 