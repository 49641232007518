.mainNavBar{
    background-color: #5e6e7e ;
    color:#3e6c87;
    width: 100%;
  }
  .mainNavBar a{
    color:#3e6c87;
    background: none;
    margin-left: 0px;
  }
  .mainNavBar a:hover{
    color:#ffffff;
  }
  
.dropdown-menu{
  background-color: #0979be;
}
.dropdown-item:hover{
  background-color: #0979be;
}

.navHeader{
  background-color:rgba(230 , 230, 230, 1);
    color:#0979be;
    width: 100%;
    min-height: 40px;
}
.subHeader{
  background-color:#0979be;
  color:#a7e4e2;
  width: 100%;
  text-align: center;
  
}
.catNavBar{
  display: block;
  margin: auto;
  text-align: center;
}
.wrapMenu{
  width: 100%;
}

.subHeader .catBtn{
  color:#ffffff;
  text-decoration: none;
  padding-left: 7px;
  padding-right: 7px;
}

.subHeader .catBtn:hover{
  color:#a7e4e2;
  text-decoration: none;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
}

.subHeader .active{
  color:#a7e4e2;
  text-decoration: none;
  padding-left: 7px;
  padding-right: 7px;
}


.categorie-menu{
  background-color: #0979be;
  color: white;
  height: 30px;
  padding: 0px;
}
.categorie-menu a{
  color:#ffffff;
  background: none;
  margin-left: 0px;
}
.categorie-menu a:hover{
  color:#a7e4e2;

}
header .logoTop{
  padding-left: 0px;
  padding-right: 20px;
}
header .logoTop img{
  height: 25px;
  cursor: pointer;
}

.categorieSub{
  background-color: #0979be;
  border-radius: 7px 0px 0px 7px;
  text-align: right;
  height: 100%;
  line-height: 40px;
  padding-right: 10px;
  padding-left: 15px;
  
}

.categorieSub a{
  color:#ffffff;
}

.categorieSub a:focus{
  color:#ffffff;
}
.categorieSub a:hover{
  color:#a7e4e2;
}


.searchBtn{
  background-color: #0979be;
  color:#ffffff;
  border-radius: 0px 7px 7px 0px;
  margin-left: 0px;
  height:100%;

}
.searchBtn:hover{
  color: #a7e4e2;
  background-color: #0979be;
}
.searchBtn:focus{
  color: #a7e4e2;
  background-color: #0979be;
}


.togglerWhite{
  border: none;
  padding: 4px 3px;
  outline: none;
  color:  #ffffff;
  border: solid #0979be 1px;
}

.togglerWhite:hover{
  border: solid #ffffff 1px;
}
.togglerWhite span{
  margin-top: 2px;
  display:block;
  width:22px;
  height: 2px;
  border: 1px;
  background-color: #ffffff;
}
.menuRight{
  float:right;
  display: inline-flex;
  margin-left: 10px;
}
.btnMenuLeft{
  margin-top: 8px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  margin-right: 5px;
  float: right;
}
.btnMenuLeft:hover{

color:#ffffff;

}

.btnFavory{
  cursor: pointer;
  display: block;
  width: 35px;
  height: 35px;
  background-image: url("../imgs/interface/coeur.png");
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: center center;
  border: solid 2px rgba(230 , 230, 230, 1);
  border-radius: 5px;
  margin-right: -5px;
  
  color: #fff;
  line-height: 28px;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 1px;
}
.btnFavory:hover{
  border: solid 2px #0979be;

}

/* Assuming you have a CSS file named header.css */
.custom-toggle {
  /* Add your custom styles here to match the appearance of panel-btn */
  /* For example: */
  background-color: transparent;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  /* Add other styles as needed */
  margin-left: auto;
  height:30px;
}

/* You may also need to adjust the icon styles */
.custom-toggle .navbar-toggler-icon {
  /* Add your custom icon styles here */
  /* For example: */
  background-image: url('../imgs/interface/panel-btn.png');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  /* Add other styles as needed */
  margin-top: -5px;
  margin-right: -15px;
}

/* You may also need to adjust the icon styles */
.custom-toggle .navbar-toggler-icon:hover {
  /* Add your custom icon styles here */
  /* For example: */
  background-image: url('../imgs/interface/panel-btn-over.png');
}

/* You may also need to adjust the icon styles */
.custom-toggle2 .navbar-toggler-icon:hover {
  /* Add your custom icon styles here */
  /* For example: */
  background-image: url('../imgs/interface/panel-btn-over.png');
}

/* Assuming you have a CSS file named header.css */
.custom-toggle2 {
  /* Add your custom styles here to match the appearance of panel-btn */
  /* For example: */
  background-color: transparent;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  /* Add other styles as needed */
  margin-left: auto;
  height:30px;
  margin-right: -8px;
}

/* You may also need to adjust the icon styles */
.custom-toggle2 .navbar-toggler-icon {
  /* Add your custom icon styles here */
  /* For example: */
  background-image: url('../imgs/interface/panel2-btn.png');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  /* Add other styles as needed */
}

.userMenu {
  position: absolute;
  width: 180px;
  right: 100px;
  top: 60px;
  background-color: #a7e4e2;
  padding:5px 15px;
  z-index: 100;
  border-radius: 5px;
}
.userMenu a{
  text-decoration: none;;
}
.userMenuBtn {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
}

.userMenuBtn:hover {
  color:#fff;
}

.lastBtn{
  border-top: solid thin #0979be;
}


.iconFavories{
  background-image: url('../imgs/interface/coeur.png');
  background-size: 20px 20px;
  background-position: center right;
  background-repeat: no-repeat;
}
.iconFavories:hover{
  background-image: url('../imgs/interface/coeurOver.png');
}



.totalItem{
  float:right;
  color:#fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin-top: -5px;
  margin-right: -5px;
  font-size: 12px;
  
  background-color: red;
  border-radius: 25px;
}

.searchForm{
  height: 40px;
  display: flex;
  width:100%;
}

.searchForm .react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 14px;
    padding-left: 5px;
    margin-top: 0px;
}

.menuDate{
  display: block;
  width: 280px;
  height:40px!important;
  margin: 0px;
  padding: 0px;
  margin-right: 15px;;
}

.labelDate{
  margin-top: -10px!important;
  
}

.searchLabel{
  margin-top: -10px!important;
}


.sousMenu{
  position: absolute;
  width: 280px;
  right: 60px;
  max-height:474px;
  top: 60px;
  background-color: #a7e4e2;
  padding:5px 15px;
  z-index: 100;
  border-radius: 5px;
  overflow-x: auto;
}

.sousMenu a{
  text-decoration: none;
  color: #0979be;
}
.sousMenu a:hover{
  text-decoration: none;
  color: #ffffff;
}

.sousTitreMenu{
  margin-bottom:20px;
  padding: 0px;
}




.panier{
  position: absolute;
  width: 350px;
  right: 10px;
  top: 60px;
  background-color: #a7e4e2;
  padding-top:15px;
  padding-bottom:15px;
  
  z-index: 100;
  border-radius: 5px;
  }
.panier .itemList{
  width: 100%;
  overflow-x: auto;
  max-height:350px;
  padding-right: 10px;
  padding-left: 10px;
  
}
.cartTitle{
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: bold;
}
.panier .btn-secondary{
  background-color:#0979be;
  color: #ffffff;
  width: 90%;
  margin-left: 5%;
}
.panier .line_item{
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  margin-bottom: 10px;
}
.panier .line_item img{
  float: left;
  width: 60px;
  height: 60px;
}
.panier .line_item .x_btn{
  cursor: pointer;
  float:right;
  display: block;
  background-color: red;
  color: #ffffff;
  font-weight: 900;
  line-height: 12px;
  text-align: center;
  font-size: 12px;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  margin-right:10px;
  margin-top: 21px;
}

.panier .line_item input{
  width:30px;
  text-align: center;
  border: thin #dddddd solid;
}

.panier .itemResume{
  border-bottom: thin solid #0979be;
  font-weight: bold;
  width:100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;

}

.panier .total{
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: right;
  font-size: 22px;
  font-weight: bold;
  padding-right: 20px;
  letter-spacing: 1px;
}
.panier .sousTotal{
  text-align: right;
  padding-right: 20px;
}
.panier .itemLocation{
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 15px;
}
.panier .line_item .itemTitle{
  margin-top: 10px;
  margin-left: 10px;
  display: block;
  float: left;
}
.panier .line_item .itemNumber{
  font-size: 12px;
  font-weight: bold;
}
.panier .line_item .itemName{
  font-size: 14px;
  width: 120px;
  height: 20px;
  overflow: hidden;
}



.quantInput{
  margin-top: 18px;
  float: right;
  display: flex;
  margin-right: 10px;
  font-size: 12px;
}
.quantInput .Plus{
  border-right: thin #dddddd solid;
  border-top: thin #dddddd solid;
  border-bottom: thin #dddddd solid;
  border-radius: 0px 10px 10px 0px;
  width: 20px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}
.quantInput .Plus:hover{
  background-color: rgba(200 ,200,200,0.5);  
}
.quantInput .Minus{
  border-left: thin #dddddd solid;
  border-top: thin #dddddd solid;
  border-bottom: thin #dddddd solid;
  border-radius: 10px 0px 0px 10px;
  width: 20px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}
.quantInput .Minus:hover{
  background-color: rgba(200 ,200,200,0.5);  
}

.panier .resume{

}

.dateSearch{
  position: absolute;
  top:50px;
  left: 125px;
  z-index: 100;
  box-shadow: 2px 2px 10px #aaaaaa;
}

.searchDateBtn{
  width: 100%;
  background-color: #0979be;
  color: #ffffff;
  padding: 10px;
  text-align:center;
  cursor: pointer;
}
.searchDateBtn:hover{
  color:#a7e4e2;
}

.horaire{
  margin-top: 20px;
  text-align: right;
}
.XbtnDate{
  position: absolute;
  background-color: red;
  color: #ffffff;
  display: block;
  width: 12px;
  height: 12px;
  font-size: 7px;
  text-align: center;
  line-height: 12px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  left:260px;
  top:31px
}
.XbtnDate:hover{
background-color: #aaaaaa;
}

.menuName {
  height: 40px;
  padding-left:10px;
  
}
.menuBonjour{
  line-height: 20px;
  text-align: right;
  font-size: 13px;
}

.userName{
  line-height: 20px;
  
  text-align: right;
  font-size: 14px;
  margin-top: -4px;
}

.userImage{
  width: 100px;
  height: 100px;
  float: right;
}
.sousTitre2{
  background-color: #0979be;
  color: #ffffff;
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
  
  
}

.sousTitreBtn{
  float: right;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: thin white solid;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 3px;
  color:#ffffff
}
.sousTitreBtn:hover{

background-color: #ffffff;
color: #0979be;

}

.emailVerified{
color:#4bc853;
border: thin solid #4bc853;
border-radius: 5px;
padding: 0px;
margin-left: 15px;
margin-right: 15px;

padding-left: 10px;
padding-right: 10px;
float: left;
}
.emailNotVerified{
color:#e21a1a;
border: thin solid #e21a1a;
border-radius: 5px;
padding: 0px;
margin-left: 15px;
margin-right: 15px;

padding-left: 10px;
padding-right: 10px;
float: left;
}
.floatRight{
  float:right;
}
.floatLeft{
  float:left;
}

.btnReenvoyer{
  
background-color:none;
float: left;
color:#999999;
border: thin solid #999999;
border-radius: 5px;
padding: 0px;
margin-left: 15px;
padding-left: 10px;
padding-right: 10px;
cursor: pointer;
}
.btnReenvoyer:hover{
background-color:#999999;
color: #ffffff;

}

.btn-secondary{
  background-color:#0979be;
}
.btn-secondary:active{
  background-color:#0979be;
}
.btn-secondary:focus{
  background-color:#0979be;
}

.btn-secondary:hover{
  background-color:#0979be;
  color:#a7e4e2;
}
.nouveauProjet{
  position: absolute;
  width: 600px;
  top:120px;
  left: 10%;
  z-index: 100;
  width: 80%;
  border: thin solid #0979be;  
  background-color: #ffffff;
}

.sousMenuProjet{
  margin: 0px;

  width: 100%;
  height: 150px;
  display: block;
  background-color: #0979be;
}
.navHeader {
  display: grid;
  grid-template-columns: 1fr auto;
}


/* Make the toggle button appear on the right of the right menu */
.navbar-toggler {
  grid-column: 3 / 4;
}

.subNav{
  display:inline-flex;
  margin:auto;
}

/* Media query to adjust the layout when the screen size changes */
@media (max-width: 990px) {
  .menuRight {
    position: absolute;
    right: 5px;
    top:5px;
  }

  .searchForm{
    margin-top: 10px;
  }

  .subNav{
    margin-top: 10px;
    display:block;
  }
  
  header .logoTop img{
    height: 25px;
    cursor: pointer;
  }

  .subHeader .catBtn{
    margin-bottom: 5px;
  }

  .trierPar{
    display: none;
  }
  
}

.searchText{
  padding-left: 10px;
}

.backgroundText{
  background-image: url('../imgs/interface/loop.png');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 99% 8px;
}
