.searchBar{
    margin-top: 10px;
    display: block;
}
.searchText{
    padding-left: 10px;
}

.searchBar .backgroundText{
    background-image: url('../imgs/interface/loop.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 99% 8px;
}
.btnSearch{
    border:none;
    border-radius: 0px 5px 5px 0px;
}