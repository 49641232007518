

.subHeader{
    position: relative;
    background-color:#0979be ;
    color:#a7e4e2;
    width: 100%;
    min-height: 40px;
    text-align: center;

  }
  
  .subHeader a{
    color:#ffffff;
    text-decoration: none;
    padding-left: 7px;
    padding-right: 7px;
  }
  
  .subHeader a:hover{
    color:#a7e4e2;
    text-decoration: none;
    padding-left: 7px;
    padding-right: 7px;
  }
  
  .subHeader .catBtn{
    color:#ffffff;
    text-decoration: none;
    padding-left: 7px;
    padding-right: 7px;
  }
  
  .subHeader .catBtn:hover{
    color:#a7e4e2;
    text-decoration: none;
    padding-left: 7px;
    padding-right: 7px;
    cursor: pointer;
  }
  
  .subHeader .activeCat{
    color:#a7e4e2;
    text-decoration: none;
    padding-left: 7px;
    padding-right: 7px;
  }

  .subHeader .panel-btn{
    position: absolute;
    display: block;
    cursor: pointer;
    width: 25px;
    height: 25px;
    left: 5px;
    margin-right: 10px;
    background-image: url("../imgs/interface/categories.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 22px 22px;
  }

.subHeader .panel-btn:hover{
    background-image: url("../imgs/interface/categories-over.png");
    
  }
  

.subNav{
    display:inline-flex;
    flex-wrap: wrap;
    margin:auto;
    height:30px;
    overflow: hidden;
  }
  

  /* Media query to adjust the layout when the screen size changes */
@media (max-width: 460px) {
  .subHeader .panel-btn{
    position: relative;
  }
}