.homeLogo{
    margin-bottom: 20px;
    max-width: 100%;
  }
  
  .homeImgs{
    margin-bottom: 20px;
    height:120px
  }

.featureImg{
    width: 100%;
  }
 
  .featurette-heading {
    font-size: 50px;
    margin-top: 7rem;
  }

  .logoText{
    height:18px;
    margin-top: -2px;
  }

  