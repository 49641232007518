@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");


body {
  margin: 0px;
  padding: 0px;
  font-family: "Oswald", sans-serif;
  background-color: #ffffff;
  color: #0979be;
}

a {
  color: #0979be;
  text-decoration: none;
}


.btn-primary {
  background-color: #0979be;
  border: 0px;
  color: #ffffff;
}
.btn-primary:focus{
  background-color: #0979be;
}
.btn-primary:active{
  background-color: #0979be;
}


.btn-primary:hover {
  background-color: #0979be;
  color: #a7e4e2;
}





/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Set the background color of the scrollbar track */
::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, #f1f1f1, #e6e6e6);
}

/* Set the color and border radius of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 2px;
}

/* Add a hover effect to the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1;
}
