.locationTitle{
    width: 100%;
    text-align: center;
    margin-top: 60px;
    padding-bottom: 20px;
    border-bottom: 2px solid #0979be;
  }
  .locationTitle img{
    margin-top: -60px;
    height: 80px;
  }
  .locationTitle span{
    font-size: 50px;
    margin-top: 8px;
    margin-left: 30px;
  }
  .texteLeft{
    text-align: right;
  }
  .inputSoumissionBig{
    width:200px;
  }
  .inputSoumissionMedium{
    width:100px;
  }
  .inputSoumissionSmall{
    width:75px;
  }
  .soumissionRow{
    margin-top: 10px;
  }
  .soumissionTextArea{
    margin-top: 5px;
    width: 60%;
    height: 160px;
    margin-bottom: 10px;
  }

  .bigMargin{
    margin-top:30px;
  }
  .mapBox{
    display: block;
    margin-top: 30px;
  }

  .location{
    padding: 10px;
    display: block;
    
  }
  .location img{
    margin-top: 10px;
    width: 150px;
    clear: both;
  }
 
  .locationTitre{
    margin-top: 10px;
    font-size: 14px;
  }

  .location p{
    margin-top: 10px;
    font-size: 15px;
  }
  
@media (max-width: 765px) {


  
  .locationTitle img{
    margin-top: -30px;
    
  }

}