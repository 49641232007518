footer{
    margin-top: 20px;
    margin-bottom: 40px;
    border-top: 2px solid #0979be;
}
footer p {
  margin-top: 20px;
  font-weight: bold;
}
footer .logoBas{
    margin-top: -6px;
    margin-left: 0px;
    margin-right: 5px;
    height: 18px;
  }
footer .btn{
    cursor: pointer;
    display: block;
    margin-top: 10px;
    margin-left: 5px;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    border:  2px solid #ffffff;
    border-radius: 5px;
    transition: 1s;
    padding: 0px;
    padding-top: 3px;
}
footer .btn:hover{
    border: 2px solid #0979be;
}
footer .Location img{
    height: 30px;
  }
footer .Email img{
    height: 23px;
    width: 30px;
  } 
footer .contact{
    font-size: 15px;
    margin-right: 10px;
    line-height: 60px;
    font-weight: bold;
  }
footer .contact img{
    width: 18px;
    height: 18px;
    margin-right: 5px;
    margin-top: -2px;
  }
footer .contactMenu{
  display: inline-flex;
  float: right;
}
footer .contactMenu{
  display: inline-flex;
  float: right;
}

footer .rights{
  float: left;
}
  /* Media query to adjust the layout when the screen size changes */
@media (max-width: 990px) {
  footer{
    display: block;
  }
}
@media (max-width: 768px) {
 footer .rights{
    float:none;
    text-align: center;
  }
  footer .contactMenu{
    float:none;
    min-width: 100%;
    justify-content: center;
  }
  footer .contactMenu .contact{
    clear:both;
  }
}
